  .Chat {
    text-align: center;
    max-width: 728px;
    margin: 0 auto;
  }
  
  .Chat header {
    background-color: #181717;
    height: 10vh;
    min-height: 50px;
    color: white;
    position: fixed;
    width: 100%;
    max-width: 728px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .Chat section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    background-color: rgb(40, 37, 53);
  }

  .onSubmit {
    height: 10vh;
    position: fixed;
    bottom: 0;
    background-color: rgb(24, 23, 23);
    width: 100%;
    max-width: 728px;
    display: flex;
    font-size: 1.5rem;
}
  
form button {
    width: 20%;
    background-color: rgb(56, 56, 143);
  }
  
  
  input {
    line-height: 1.5;
    width: 100%;
    font-size: 1.5rem;
    background: rgb(58, 58, 58);
    color: white;
    outline: none;
    border: none;
    padding: 0 10px;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  
  .sign-in {
    color: #282c34;
    background: white;
    max-width: 400px;
    margin: 0 auto;
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
  }

  .sign-out{
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
  }
  
  .submit{
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
  }

  ul, li {
    text-align: left;
    list-style: none;
  }
  
  p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
  }
  
  .message {
    display: flex;
    align-items: center;
  }
  
  
  .sent {
    flex-direction: row-reverse;
  }
  
  .sent p {
    color: white;
    background: #0b93f6;
    align-self: flex-end;
  }
  .received p {
    background: #e5e5ea;
    color: black;
  }
  
 