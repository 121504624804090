*{
  margin: 0;
  padding: 0;
}
.share-game{
  position: absolute;
  width: 30%;
  bottom: 0;
  left: 0;
}

.app-container{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(34,34,34);
}

.pointer{
  cursor: pointer;
}

.has-bg-img{ background: url('./assets/janiKaasinen.jpg') center center; background-size:cover;}

.user-form{
  margin-top: 30px !important;
  max-width: 800px;
  margin: auto;
}

.home{
  min-height: 100vh;
}

.home-columns{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Rcontainer{
  text-orientation: upright;
  writing-mode: vertical-lr;
  font-family: sans-serif;
  padding: 10px;
  color:white;
}

.Rcontainer button{
  margin-top: 20px;
  cursor: pointer;
  background-color: rgb(63,63,63);
  border: 2px solid white;
  border-radius: 10px;
}

.board-container {
  width: 40%;
  height: 85vh;
}

.history{
  width: 600px;
  height: 600px;
}

.board {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.square{
  width: 12.5%;
  height: 12.5%;
}

.promote-square{
  width: 50%;
  height: 50%;
}

.pPointer{
  cursor: pointer;
}

.square-black {
  background-color: #B59963;
}

.square-white {
  background-color: #F0D9B5;
}

.board-square {
   width: 100%;
   height: 100%;
}

.piece-container{
  cursor: grab;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.piece {
  max-width: 70%;
  max-height: 70%;
}

.vertical-text {
  text-orientation: upright;
  writing-mode: vertical-lr;
  font-family: sans-serif;
  padding: 10px;
  color:white;
}

.vertical-text button{
  margin-top: 20px;
  cursor: pointer;
  background-color: rgb(63,63,63);
  border: 2px solid white;
  border-radius: 10px;
}